module.exports = require('deepmerge')(require('./config.default.js'), {
  native: {
    name: {
      default: 'USH_D'
    },
    id: 'com.mobilespot.ush.dev',
    // App bundle name
    // iOS
    appleTeamId: '2BAP3P29V2',
    // iosAppStoreUrl: 'https://itunes.apple.com/app/id1484463502',
    // Android
    // 'androidapp-id': 'TODO', // unused?
    playStoreUrl: ''
  },
  web: {
    url: 'ush-dev.mobile-spot.com'
  },
  version: {
    codepushDeploymentVersion: {
      iOS: "",
      android: ""
    }
  },
  codePush: {
    android: {
      CodePushDeploymentKey: ''
    },
    iOS: {
      CodePushDeploymentKey: ''
    }
  }
});