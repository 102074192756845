import React from 'react';
import PropTypes from 'prop-types';

import './ContactInfo.scss';

const HARD_CODED_FIELDS = ['Societe', 'Prenom', 'Nom'];

function getIcon(formFieldCode) {
  switch (formFieldCode) {
    case 'Societe':
      return <span className="kl-fs-icon kl-ci-icon-company fad fa-suitcase" />;

    case 'Nom':
      return <span className="kl-fs-icon kl-ci-icon-name fas fa-user" />;

    case 'Email':
      return <span className="kl-fs-icon kl-ci-icon-other fas fa-pen" />;

    default:
      if (formFieldCode.startsWith('Telephone')) {
        return <span className="kl-fs-icon kl-ci-icon-other fas fa-phone-alt" />;
      }
      return <span className="kl-fs-icon kl-ci-icon-other" />;
  }
}

function ContactInfo({ contact, readOnlyFields, labels }) {
  const remainingFields = readOnlyFields.filter(
    (field) => HARD_CODED_FIELDS.indexOf(field.Code) === -1
  );

  return (
    <>
      <div className="kl-ci-contact-data-row1">
        {contact.Societe && (
          <div>
            {getIcon('Societe')}
            <span className="kl-ci-company">{contact.Societe || ''}</span>
          </div>
        )}
        {(contact.Prenom || contact.Nom) && (
          <div>
            {getIcon('Nom')}
            <span className="kl-ci-name">
              <span className="kl-ci-firstname">{contact.Prenom || ''}</span>
              <span className="kl-ci-lastname">{contact.Nom || ''}</span>
            </span>
          </div>
        )}
      </div>
      <div className="kl-ci-contact-data-row2">
        {remainingFields.map(function(field) {
          const value = contact[field.Code];
          return !value ? null : (
            <div key={field.Code}>
              {getIcon(field.Code)}
              <span className="kl-ci-other">
                {field.Label ? `${field.Label} : ` : ''} {value}
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
}

ContactInfo.propTypes = {
  contact: PropTypes.object.isRequired,
  readOnlyFields: PropTypes.array.isRequired,
  labels: PropTypes.object.isRequired,
};

export default ContactInfo;
