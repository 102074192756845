import React from 'react';
import PropTypes from 'prop-types';

import FooterGenericButton from './FooterGenericButton';

function FooterBackButton({ labels, callback }) {
  return (
    <FooterGenericButton className="klf-back-btn" callback={callback}>
      <div className="klf-back-btn-icon far fa-arrow-left" />
      <div className="klf-back-btn-label content-font">{labels.klipsoLeads.back}</div>
    </FooterGenericButton>
  );
}

FooterBackButton.propTypes = {
  labels: PropTypes.object.isRequired,
  callback: PropTypes.func,
};

export default FooterBackButton;
