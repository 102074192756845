import React from 'react';

import CTAButton from 'src/components/cta-button/CTAButton';

export default ({ labels, actions, goToSimple }) => (
  <>
    <CTAButton
      id="sfs-go-to-simple-btn"
      action={goToSimple}
      className="btn-as-text onResult"
      label=""
    >
      <i className="fas fa-chevron-left"></i>
      <span>{labels.searchTaiga.goToSimpleSearch}</span>
    </CTAButton>
    <CTAButton
      id="sfs-edit-btn"
      label={labels.searchTaiga.editSearch}
      action={actions.taigaSearchClear}
    />
  </>
);
