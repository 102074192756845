import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { debounce } from 'lodash-custom';

import { MOBIGEO_LOG_LEVEL, MAP_TYPE } from 'data/config/mapConfig';
import { MAP_PAGE_KEY, GMAP_PLACES_PAGE_KEY } from 'src/pages/pagesKeys';

import Menu from 'src/components/menu/Menu';
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import MobilespotButton from 'src/components/mobilespot-button/MobilespotButton';
import AdBanner from 'src/components/ad-banner/AdBanner';

import { init as initFeatureStatus } from 'src/core/mapFeatures';
import { fetchHappeningsDataLumpFromDB as happeningsData } from 'src/core/happenings/happenings';

import * as actions from 'src/store/actions';
import MobiGeoAssetsProvider from './MobiGeoAssetsProvider';
import { shouldAutoStart } from './locationHelper';
import { isCordovaContext } from '../../core/util/browser';
import Select from 'react-select';

import MapContext from './MapContext';

import './MapPage.scss';
import CTAButton from 'src/components/cta-button/CTAButton';

const LOG_PREF = '[MapPage] ';

const getMobiGeoLoadOptions = (props) => ({
  lang: props.labels.id,
});

const getMobiGeoMapCreateOptions = () => ({
  showWatermark: false,
});

class MapPage extends Component {
  constructor(props) {
    super(props);

    this.pageKey = MAP_PAGE_KEY;

    // @see ../../README.md#using-global-variables
    this.MobiGeo = window.MobiGeo;
    if (this.MobiGeo) {
      this.MobiGeo.setLogLevel(MOBIGEO_LOG_LEVEL);
      this.bindEventHandlers();
    }
    this.containerId = 'map-container';

    this.loadDataset = debounce(this._loadDataset.bind(this), 150);
    this.filterMap = this.filterMap.bind(this);
    this.initGoogleMap = this.initGoogleMap.bind(this);
    this.handleCategoriesFilter = this.handleCategoriesFilter.bind(this);
    this.handleLegendDialog = this.handleLegendDialog.bind(this);
    
    this.state = {
      data : [],
      initialData : null,
      map: null,
      gmapplaceCategorie: null,
      legendModalOpen: false,
      iconsLegend: []
    }
  }

  /**
   * Update string visible in browser tab/history/favorites
   */
  setDocumentContext() {
    if (this.props.isActive(this.pageKey)) {
      this.props.setDocumentContext(this.getPageTitle());
    }
  }

  /**
   * String displayed in app toolbar
   * @return {string}
   */
  getPageTitle() {
    return this.props.labels.map.title;
  }

  /**
   * Listen to MobiGeo events
   */
  bindEventHandlers() {
    // Map loaded
    this.MobiGeo.Map.on('ready', () => {
      MapContext.setStatusLoaded();

      if (this.props.shouldRestart) {
        this.restart();
      } else {
        console.log(`${LOG_PREF}Map has successfully been loaded`);

        // Refresh features availability
        initFeatureStatus();

        // Update state (e.g shouldRestart=false)
        this.props.actions.mapLoaded();
      }
    });
  }

  restart() {
    this.props.actions.mapIsRestarting();
    MapContext.setStatusRestarting();
    console.log(`${LOG_PREF}Map restart`);
    this.loadDataset();
  }

  _loadDataset() {
    if (this.props.isDataReady !== true) {
      console.log(`${LOG_PREF}Skipping map loading because data is not available yet`);
      return;
    }

    if (!MapContext.isLoading() && MAP_TYPE === 'mobigeo') {
      console.info(`${LOG_PREF}Load map`);
      MapContext.setStatusLoading();
      this.MobiGeo.Location.autoStart = shouldAutoStart();
      this.MobiGeo.Map.POI.disablePopups = true;
      this.MobiGeo.setProviders(MobiGeoAssetsProvider);

      this.MobiGeo.load(this.props.mobigeoApiKey, getMobiGeoLoadOptions(this.props), (err) => {
        if (err) {
          console.error(`${LOG_PREF}MobiGeo failed to unlock data set because: ${err}`);
          MapContext.setStatusNotLoaded();
          return;
        }
        this.MobiGeo.Map.create(
          document.getElementById(this.containerId),
          getMobiGeoMapCreateOptions(),
          (err) => {
            if (err) {
              console.warn(`${LOG_PREF}MobiGeo.Map.create thrown error ${err}`);
              this.props.actions.mobigeoErrorThrown(err, 'MobiGeo.Map.create');

              if (err === 'MAP_ERROR_INVALID_PARENT_SIZE') {
                const delay = 1;
                console.info(`${LOG_PREF}Map container is too small, retry in ${delay} second(s).`);
                window.setTimeout(this.loadDataset, 1 * 1000);
              }
              MapContext.setStatusNotLoaded();
            }
            // in case of success, see 'ready' event handler (see bindEventHandlers)
          }
        );
      });
    }
  }

  UNSAFE_componentWillMount() {
    this.toggleLoaderAccordingToDataReadyStatus(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.poi && nextProps.poi !== this.props.poi && MAP_TYPE === 'google') {
      var poiShow = this.props.gmapplaces.filter((place) => {
        return place.id == nextProps.poi.placeId;
      });
      
      this.filterMap(poiShow[0], null, false);
    } else {
      this.toggleLoaderAccordingToDataReadyStatus(nextProps);
    }
  }

  toggleLoaderAccordingToDataReadyStatus(props) {
    if (props.isDataReady !== true) {
      this.props.actions.showFullLoader();
    } else {
      this.props.actions.hideFullLoader();
    }
  }

  initGoogleMap () {
    // Define a div tag with id="map_canvas"
    var mapDiv = document.getElementById('g-map');
    // Initialize the map plugin
    var map = plugin.google.maps.Map.getMap(mapDiv, {
      'controls': {
        'zoom': true,          // android only
        'mapToolbar': false     // android only (itinerary button)
      }});
    this.setState({map});
    // The MAP_READY event notifies the native map view is fully ready to use.
    map.one(plugin.google.maps.event.MAP_READY, onMapInit.bind(this));
    function onMapInit() {
      // Add markers
      var bounds = [];
      var markers = [];
      let myActions = this.props.actions;
      var htmlInfoWindow = new plugin.google.maps.HtmlInfoWindow();
      function onMarkerClick() {
        var marker = this;
        marker.hideInfoWindow();
        let myHtml = document.createElement("div");
        myHtml.innerHTML = "<div style='text-align: center;'><h4>"+marker.get("address")+"</h4><button>En savoir plus</button></div>";
        let button = myHtml.getElementsByTagName("button")[0];
        const gmapplaceId = marker.get("gmapplaceId");
        button.addEventListener("click", function() {
          myActions.navigate(GMAP_PLACES_PAGE_KEY, {id: gmapplaceId});
          htmlInfoWindow.close();
        });
        htmlInfoWindow.setContent(myHtml, {width: "220px"});
        htmlInfoWindow.open(marker);
      }
      let poiToShow = this.props.poi;
      this.props.gmapplaces.map(function(options) {
        options.gmapplaceId = options.id;
        options.gmapplaceTitle = options.title;
        delete options.title;
        options.icon = options.icon.color || 'yellow';
        var myMarker = map.addMarker(options, function(marker) {
          marker.on(plugin.google.maps.event.MARKER_CLICK, onMarkerClick);
          if(poiToShow && poiToShow.id===options.id) {
            marker.showInfoWindow();
          }
        });
        options.title = options.gmapplaceTitle;
        bounds.push(options.position);
        markers.push(myMarker);
      });
      if(this.props.poi) {
        var poiShow = this.props.gmapplaces.filter((place) => {
          return place.id == this.props.poi.placeId;
        });
        if(poiShow[0]) {
          // Set a camera position that includes all markers.
          map.moveCamera({
            target: [poiShow[0].position],
            /// zoom: 15,
          }, function() {
            //alert("Camera target has been changed");
          });
        } else {
          // Set a camera position that includes all markers.
          map.moveCamera({
            target : bounds,
            // target: {lat: 48.83689441017694, lng: 2.3334175410194558},
            /// zoom: 15,
          }, function() {
            //alert("Camera target has been changed");
          });
        }
      } else {
        // Set a camera position that includes all markers.
        map.moveCamera({
          target : bounds,
          // target: {lat: 48.83689441017694, lng: 2.3334175410194558},
          /// zoom: 15,
        }, function() {
          //alert("Camera target has been changed");
        });
      }
      // open the last marker.

      // The MAP_READY event notifies the native map view is fully ready to use.
    }    
  }

  /**
   * Note: returning true does not result in MobiGeo loading again from scratch.
   */
  shouldComponentUpdate(nextProps) {
    if (nextProps.isDataReady !== true) {
      return false;
    }

    // Window has been resize
    if (this.props.lastResize !== nextProps.lastResize) {
      if (MapContext.isNotLoaded()) {
        this.loadDataset();
      } else {
        this.MobiGeo.Map.resize();
      }

      if (this.props.orientation !== nextProps.orientation) {
        return true;
      }

      return false;
    }

    if (this.props.gmapplaces !== nextProps.gmapplaces) {
      nextProps.gmapplaces.map((gmapplace) => {
        this.setState((prevState) => {
          return {...prevState, iconsLegend: [...prevState.iconsLegend, gmapplace.icon]}
        });
      });
      this.initGoogleMap();
      
      return true;
    }

    // Data or assets have been updated
    /* if (this.props.shouldRestart !== nextProps.shouldRestart) {
            if (nextProps.shouldRestart === true) {
                MapContext.setStatusNotLoaded(); // => MobiGeo reload
            }
        } */

    return true;
  }


  /**
   * Load MobiGeo dataset if data was not ready when the component has been mounted.
   * (e.g direct access to the page via url)
   */
  componentDidUpdate() {
    if (this.props.shouldRestart) {
      this.restart();
    }
    this.setDocumentContext();
  }

  /**
   * Load MobiGeo dataset if data is ready when the component is mounted.
   * (e.g coming from another page)
   */
  componentDidMount() {
    this.loadDataset();
    this.setDocumentContext();
    if(isCordovaContext() && MAP_TYPE === 'google') {
      this.props.actions.fetchGmapPlaces();
    }
  };

  filterMap (place = null, categorie = null, clearFilter = false)  {
    let myMap = this.state.map;
    let bounds = [];
    let myActions = this.props.actions;
    if(clearFilter || place) {
      myMap.clear();
      var htmlInfoWindow = new plugin.google.maps.HtmlInfoWindow();
      function onMarkerClick(marker) {
        var marker = this;
        marker.hideInfoWindow();
        let myHtml = document.createElement("div");
        myHtml.innerHTML = "<div style='text-align: center;'><h4>"+marker.get("address")+"</h4><button>En savoir plus</button></div>";
        let button = myHtml.getElementsByTagName("button")[0];
        const gmapplaceId = marker.get("gmapplaceId");
        button.addEventListener("click", function() {
          myActions.navigate(GMAP_PLACES_PAGE_KEY, {id: gmapplaceId});
          htmlInfoWindow.close();
        });
        htmlInfoWindow.setContent(myHtml, {width: "220px"});
        htmlInfoWindow.open(marker);
      }
      this.props.gmapplaces.map(function(options) {
        options.gmapplaceId = options.id;
        options.gmapplaceTitle = options.title;
        options.icon = options.icon.color || 'yellow';
        delete options.title;
        myMap.addMarker(options, function(marker) {
          marker.on(plugin.google.maps.event.MARKER_CLICK, onMarkerClick);
        });
        options.title = options.gmapplaceTitle;
        bounds.push(options.position);
      });
      myMap.moveCamera({
        target: bounds
      });
    }
    if(categorie) {
      var filteredPlaces = this.props.gmapplaces && this.props.gmapplaces.filter(function(place){
        return place.lump.cats && place.lump.cats.includes(parseInt(categorie));
      });
      myMap.clear();
      var htmlInfoWindow = new plugin.google.maps.HtmlInfoWindow();
      function onMarkerClick() {
        var marker = this;
        marker.hideInfoWindow();
        let myHtml = document.createElement("div");
        myHtml.innerHTML = "<div style='text-align: center;'><h4>"+marker.get("address")+"</h4><button>En savoir plus</button></div>";
        let button = myHtml.getElementsByTagName("button")[0];
        const gmapplaceId = marker.get("gmapplaceId");
        button.addEventListener("click", function() {
          myActions.navigate(GMAP_PLACES_PAGE_KEY, {id: gmapplaceId});
          htmlInfoWindow.close();
        });
        htmlInfoWindow.setContent(myHtml, {width: "220px"});
        htmlInfoWindow.open(marker);
      }
      filteredPlaces.map(function(options) {
        options.gmapplaceId = options.id;
        options.gmapplaceTitle = options.title;
        delete options.title;
        options.icon = options.icon.color || 'yellow';
        myMap.addMarker(options, function(marker) {
          marker.on(plugin.google.maps.event.MARKER_CLICK, onMarkerClick);
          if(place && place.id===options.id) {
            marker.showInfoWindow();
          }
        });
        options.title = options.gmapplaceTitle;
        bounds.push(options.position);
      });
      myMap.moveCamera({
        target: bounds,
        zoom: 15
      });
    }
    if(place) {
      myMap.moveCamera({
        target: place.position,
        zoom: 15
      });
    }
  };
  
  handleCategoriesFilter (event){
    this.setState({gmapplaceCategorie: event.id}, () => {
      this.filterMap(null, this.state.gmapplaceCategorie,false)
    });
  };

  handleLegendDialog(value) {
    this.setState({legendModalOpen: value});
  }

  render() {
    console.log(`${LOG_PREF}render`);

    // Always keep that page's DOM
    // if (!this.props.isVisible) return null;
    if (this.props.isDataReady !== true) {
      return null;
    }
    return (
      <>
        <Menu
          actions={this.props.actions}
          labels={this.props.labels}
          profile={this.props.profile}
          options={this.props.menuOptions}
          associatedPageKey={this.pageKey}
          adConfig={this.props.adConfig}
          twoColumns={this.props.twocolumns}
          isLocationEnabled={this.props.isLocationEnabled}
        />

        <AppToolbar
          labels={this.props.labels}
          isDisplayed={this.props.hasToolbar}
          actions={this.props.actions}
          title={this.getPageTitle()}
          pageKey={this.pageKey}
          profile={this.props.profile}
          hasBackButton={this.props.backButtonInToolbar}
          hasHomeButton={this.props.homeButtonInToolbar}
          hasFavButton={this.props.favButtonInToolbar}
          hasSearchButton={this.props.searchButtonInToolbar}
          hasMenuButton={this.props.menuButtonInToolbar}
        />

        {happeningsData() && (
          <AdBanner
            ad={happeningsData()}
            adFiles={happeningsData().files}
            currentLang={this.props.labels.id}
            actions={this.props.actions}
          />
        )}

        <div id="map-container-wrapper" className="content-below-apptoolbar" style={{ height: happeningsData() ? "calc(100% - 130px)" : "calc(100% - 48px)"}} >
          { 
            MAP_TYPE === 'mobigeo' &&
            /* MobiGeo container */
            <>
              <div id={this.containerId} />
              {this.props.hasMobileSpotButton && <MobilespotButton labels={this.props.labels} />}
            </>
          }
          {
            MAP_TYPE === 'google' && 
            /* GoogleMaps container */
            <div>
              <div id={this.containerId}>
                <div id="g-map" />
              </div>
              <div className="gmap-buttons">
                {this.props.gmapplacesCategories && 
                  <Select
                    placeholder={this.props.labels.common.filterByCategory}
                    options={this.props.gmapplacesCategories}
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    onChange={this.handleCategoriesFilter}
                    menuPlacement="top"
                    isSearchable={ false }
                  />
                }
                <CTAButton  action={() => {this.filterMap(null, null, true)}} label={this.props.labels.common.seeAll} />
              </div>
            </div>
          }
        </div>
      </>
    );
  }
}

MapPage.propTypes = {
  gmapplaces: PropTypes.array,
  gmapplacesCategories: PropTypes.array,
  isPending: PropTypes.bool,
  lastResize: PropTypes.number,
  isDataReady: PropTypes.bool,
  shouldRestart: PropTypes.bool,
  mobigeoApiKey: PropTypes.string.isRequired,
  hasMobileSpotButton: PropTypes.bool,
  // Common page props
  menuOptions: PropTypes.object.isRequired,
  profile: PropTypes.string,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isActive: PropTypes.func.isRequired,
  setDocumentContext: PropTypes.func.isRequired,
  isLocationEnabled: PropTypes.bool,
  isVisible: PropTypes.bool, // set by togglePageAfterNavigation common reducer function
  isAppVisible: PropTypes.bool,
  // toolbar
  hasToolbar: PropTypes.bool,
  homeButtonInToolbar: PropTypes.bool,
  backButtonInToolbar: PropTypes.bool,
  searchButtonInToolbar: PropTypes.bool,
  favButtonInToolbar: PropTypes.bool,
  menuButtonInToolbar: PropTypes.bool,
  routingDestinationPoi: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  return state[MAP_PAGE_KEY];
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(MapPage);
