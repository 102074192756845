import React from 'react';
import PropTypes from 'prop-types';

import config from 'data/config/config';

import FlagButton from 'src/components/choose-lang-inline/FlagButton';

import './Header.scss';

function Header({ subtitle, onCloseClick, labels, actions }) {
  const hasCloseButton =
    config.KLIPSO_LEADS.HAS_HEADER_CLOSE_BUTTON && typeof onCloseClick === 'function';
  const displayLabelInsteadOfIcon = config.KLIPSO_LEADS.LABEL_INSTEAD_OF_ICON;

  return (
    <div className="klipso-leads-header title-font">
      <div className="klh-left">
        {config.SUPPORTED_LANGS && config.SUPPORTED_LANGS.length > 1 && (
          <FlagButton lang={labels.id} labels={labels} onClick={actions.showChooseLangDialog} />
        )}
      </div>
      <div className="klh-center">
        <span className="klh-title" />
        {subtitle && <span className="klh-subtitle">{subtitle}</span>}
      </div>

      <div className="klh-right">
        {hasCloseButton && (
          <span
            className={`klh-close-btn ${
              displayLabelInsteadOfIcon ? 'klh-close-label' : 'fad fa-times-square'
            }`}
            onClick={onCloseClick}
          >
            {displayLabelInsteadOfIcon && labels.klipsoLeads.close}
          </span>
        )}
      </div>
    </div>
  );
}

Header.propTypes = {
  subtitle: PropTypes.string,
  onCloseClick: PropTypes.func,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default Header;
