// const {VISITOR_PRO_PROFILE, VISITOR_GP_PROFILE, PRESS_PROFILE } = require('./profiles');
var _require = require('../../src/pages/pagesKeys'),
    HOME_PAGE_KEY = _require.HOME_PAGE_KEY,
    FAVORITES_PAGE_KEY = _require.FAVORITES_PAGE_KEY,
    LIST_GROUPS_PAGE_KEY = _require.LIST_GROUPS_PAGE_KEY,
    KLIPSO_LEADS_PAGE_KEY = _require.KLIPSO_LEADS_PAGE_KEY,
    INBOX_PAGE_KEY = _require.INBOX_PAGE_KEY,
    LOGIN_PAGE_KEY = _require.LOGIN_PAGE_KEY,
    USER_DATA_PAGE_KEY = _require.USER_DATA_PAGE_KEY,
    SEARCH_PAGE_KEY = _require.SEARCH_PAGE_KEY,
    SEARCH_TAIGA_PAGE_KEY = _require.SEARCH_TAIGA_PAGE_KEY;

import { isSessionValid } from 'src/core/login/LoginService';
import config from 'data/config/config';
import { getBindedActions } from 'src/store/bindedActions';
import { get as getLabels } from 'src/core/Lang';
import qrcodeReaderIcon from 'data/public/icons/qrcode-reader.svg';
/**
 * Toolbar configuration per profile
 */

export default function (profile, pageKey) {
  // Home does not have AppToolbar component
  if (pageKey === HOME_PAGE_KEY) {
    return {};
  }

  switch (profile) {
    // Same for every profile
    // case PROFILE_VISITEUR:
    // case PROFILE_EXPOSANT:
    // case PROFILE_PRESSE:
    default:
      return {
        homeButtonInToolbar: true,
        favButtonInToolbar: pageKey !== FAVORITES_PAGE_KEY,
        searchButtonInToolbar: pageKey !== SEARCH_PAGE_KEY && pageKey !== SEARCH_TAIGA_PAGE_KEY,
        menuButtonInToolbar: true,
        //listToolBarButton: homeToolbarIcon(),
        // Used when some profiles have access to reduced version of the app:
        // (e.g http://eurosatory-dev.mobile-spot.com/?profile=webapp-exh)
        rootCategoryButtonInToolbar: false && (pageKey === LIST_PAGE || pageKey === LIST_GROUPS_PAGE_KEY)
      };
  }
}

function getLoginIcon() {
  return {
    filePath: '',
    alt: '',
    isFontAwesome: 'fa fa-user',
    isFontProject: '',
    style: {},
    className: 'ht-icon ht-login-icon' + (isSessionValid() ? ' connected' : ''),
    label: getLabels().common.login,
    action: function action() {
      getBindedActions().navigate(isSessionValid() ? USER_DATA_PAGE_KEY : LOGIN_PAGE_KEY);
    },
    badge: null
  };
  return null;
}

function getFavoritesIcon() {
  return {
    filePath: '',
    alt: '',
    isFontAwesome: 'fa fa-star',
    //'fa fa-star',
    isFontProject: '',
    // e
    style: {},
    className: 'ht-icon  ht-favorites-icon ',
    label: getLabels().common.goToFavorites,
    action: function action() {
      getBindedActions().navigate(FAVORITES_PAGE_KEY);
    },
    badge: null
  };
}

function getInboxIcon() {
  return {
    filePath: '',
    alt: '',
    isFontAwesome: 'fa fa-envelope',
    isFontProject: '',
    style: {},
    className: 'ht-icon ht-inbox-icon',
    label: getLabels().common.goToInbox,
    action: function action() {
      getBindedActions().navigate(INBOX_PAGE_KEY);
    },
    badge: null // global.isCordovaContext ? this.props.inboxCount : null,

  };
}

function getKlipsoLeadsIcon() {
  return {
    filePath: qrcodeReaderIcon,
    alt: '',
    isFontAwesome: '',
    isFontProject: '',
    style: {},
    className: 'ht-kl-icon',
    label: getLabels().common.KlipsoLeads,
    action: function action() {
      getBindedActions().navigate(KLIPSO_LEADS_PAGE_KEY);
    },
    badge: null
  };
}

function getContactScanIcon() {
  return {
    filePath: qrcodeReaderIcon,
    alt: '',
    isFontAwesome: '',
    isFontProject: '',
    style: {},
    className: 'ht-kl-icon',
    label: getLabels().common.contactScan,
    action: function action() {
      getBindedActions().scanContact();
    },
    badge: null
  };
}

function getSearchIcon() {
  return {
    filePath: '',
    alt: '',
    isFontAwesome: 'fa fa-search',
    isFontProject: '',
    style: {},
    className: 'ht-icon ht-search-icon',
    label: getLabels().common.goToSearch,
    action: function action() {
      getBindedActions().navigate(SEARCH_PAGE_KEY);
    },
    badge: null
  };
}

function getMenuIcon() {
  return {
    filePath: '',
    alt: '',
    isFontAwesome: 'fa fa-bars',
    isFontProject: '',
    style: {},
    className: 'ht-icon ht-menu-icon',
    label: getLabels().common.openMenu,
    action: function action() {
      getBindedActions().openMenu(HOME_PAGE_KEY);
    },
    badge: null
  };
}
/**
 * Toolbar configuration for home
 */


export function homeToolbarIcon() {
  var array = []; // login Icon

  if (config.LOGIN && config.LOGIN.FEATURE_ENABLED) array.push(getLoginIcon()); // Favorite Icon

  array.push(getFavoritesIcon()); // Inbox Icon

  array.push(getInboxIcon()); // Klipso Leads Icon

  if (config.KLIPSO_LEADS && config.KLIPSO_LEADS.FEATURE_ENABLED) array.push(getKlipsoLeadsIcon()); // Contact Scan

  if (config.CONTACTS && config.CONTACTS.FEATURE_ENABLED) array.push(getContactScanIcon()); // Search Icon

  array.push(getSearchIcon()); // Menu Icon

  array.push(getMenuIcon());
  return array;
}