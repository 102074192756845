import {
  CONFIG_JSON_LOADED,
  CORDOVA_RESUME,
  PROFILE_CHANGED,
  SHOW_INTERSTICIEL,
  DATA_ASSETS_UPDATED,
} from 'src/store/actionTypes';

import { showIntersticiel } from 'src/store/actions';

import { DATA_TYPE_HAPPENINGS } from 'data/config/dataConfig';

import * as Db from 'src/core/data-and-assets/Db.js';
import { getAdConfig } from 'src/core/config-json/ConfigJsonManager';
import { get as getProfile } from 'src/core/Profile';

let resumeActionCount = 0;
let profile = getProfile();
let n;

/**
 * Read the configuration indicating Intersticiel
 * component display frequency on BG/FG switch
 */
const setNValue = () => {
  // const adConfig = getAdConfig(profile);
  const happenings = Db.getSortedAndTransformedData()[DATA_TYPE_HAPPENINGS];
  const interstitiels = happenings && happenings[0] && happenings[0].lump && happenings[0].lump.interstitiel;
  if (interstitiels && interstitiels.foreground) {
    n = Number(interstitiels.foregroundFrequency);
  }
};

export default ({ dispatch, getState }) => (next) => (action) => {
  const result = next(action);
  switch (action.type) {
    case PROFILE_CHANGED:
      profile = action.profile;
      setNValue();
      break;

    case CONFIG_JSON_LOADED:
      // setNValue();
      break;

    case DATA_ASSETS_UPDATED:
      setNValue();
      // showIntersticiel();
      break;

    case CORDOVA_RESUME:
      resumeActionCount++;
      if (typeof n === 'number' && resumeActionCount % n === 0) {
        dispatch(showIntersticiel());
      }
      break;

    case SHOW_INTERSTICIEL:
      if (typeof action.next === 'function') {
        // Delay for cleaner UX
        window.setTimeout(action.next, 200);
      }
      break;

    default:
  }
  return result;
};
