import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Header from '../header/Header';
import Content from '../content/Content';
import ContactInfo from './ContactInfo';
import Footer from '../footer/Footer';
import FooterGenericButton from '../footer/FooterGenericButton';
import InputModal from 'src/components/form/InputModal';
import CheckBoxes from 'src/components/form/CheckBoxes';
import TextField from 'src/components/form/TextField';
import InputSwitch from 'src/components/form/InputSwitch';

import { FIELD_TYPES, saveContact } from 'src/core/klipso-leads/KlipsoLeadsData';
import { saveVisitorRelationList } from 'src/core/webservices/klipso-leads/KlipsoLeadsWS';
import { formatLongDateTime } from 'src/core/Lang';
import { testEmail } from 'src/core/util/StringUtil';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';

import './FormScreen.scss';

const LOG_PREF = '[FormScreen] ';

global.klMockHistoryDateDelta = 0;
// e.g: -24*60*60*1000 => 24 hours ago

const FIELDS_INPUT_TYPE = {
  [FIELD_TYPES.EDIT_TEXT]: 'text',
  [FIELD_TYPES.TEXT_AREA]: 'textarea',
  [FIELD_TYPES.DATE_TIME]: 'date',
  [FIELD_TYPES.NUMERIC]: 'number',
  [FIELD_TYPES.BOOLEAN]: 'switch',
  [FIELD_TYPES.SINGLE_CHOICE]: 'radio',
  [FIELD_TYPES.MULTI_CHOICE]: 'checkbox',
  [FIELD_TYPES.EMAIL]: 'email',
};

const dateToString = (d) => (!d ? '' : formatLongDateTime(d, true, true));

function getDateTimestamp(d) {
  if (typeof d === 'string' || typeof d === 'number') {
    return new Date(d).getTime();
  }
  return d.getTime();
}

function filterMandatoryFields(fields) {
  return fields.filter((field) => field.IsMandatory);
}

function filterFieldsWithADefaultValue(fields) {
  return fields.filter((field) => !!field.DefaultValue);
}

function contactHasHistory(contact) {
  return Array.isArray(contact.history) === true && contact.history.length > 0;
}

class FormScreen extends React.Component {
  constructor(props) {
    super(props);
    this.renderEditableFormField = this.renderEditableFormField.bind(this);
    this.renderClickableInput = this.renderClickableInput.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.getNotFilledMandatoryFields = this.getNotFilledMandatoryFields.bind(this);
    this.highlightNotSetMandatoryFields = this.highlightNotSetMandatoryFields.bind(this);
    this.getFieldClassName = this.getFieldClassName.bind(this);
    this.isFieldEmpty = this.isFieldEmpty.bind(this);
    this.save = this.save.bind(this);
    this.exitForm = this.exitForm.bind(this);
    this.renderModalInput = this.renderModalInput.bind(this);

    this.state = {
      modifiedFields: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(LOG_PREF+'[getDerivedStateFromProps] nextProps', nextProps, 'prevState', prevState);

    if (!prevState.contactCode || prevState.contactCode !== nextProps.contact.code) {
      // New contact passed as prop

      let newStateValues = {
        contactCode: nextProps.contact.code,
        modifiedFields: {},
        highlightedFields: {},
      };

      if (contactHasHistory(nextProps.contact) !== true) {
        // Contact has no history, so it is the first time the form is displayed for this person
        // So let's initialize modified fields with default values

        let fieldsWithADefaultValue = filterFieldsWithADefaultValue(nextProps.editableFields);

        fieldsWithADefaultValue.forEach(function(field) {
          newStateValues.modifiedFields[field.Code] = field.DefaultValue;
        });
      }

      return newStateValues;
    }

    return null;
  }

  isFieldEmpty(field) {
    let value = this.getValue(field.Code),
      isSet = true;

    switch (field.Type) {
      case FIELD_TYPES.MULTI_CHOICE:
        if (Array.isArray(value) !== true || value.length === 0) {
          isSet = false;
        }
        break;

      case FIELD_TYPES.EDIT_TEXT:
      case FIELD_TYPES.TEXT_AREA:
      case FIELD_TYPES.SINGLE_CHOICE:
        isSet = typeof value === 'string' && value.length > 0;
        break;

      default:
      // Other types are in stand-by for now (see Jira ticket SAN-1)
    }

    return isSet !== true;
  }

  getNotFilledMandatoryFields() {
    let mandatoryFields = filterMandatoryFields(this.props.editableFields);
    return mandatoryFields.filter(this.isFieldEmpty);
  }

  allMandatoryFieldsSet() {
    let notFilledMandatoryFields = this.getNotFilledMandatoryFields();
    return notFilledMandatoryFields.length === 0;
  }

  highlightNotSetMandatoryFields() {
    let notFilledMandatoryFields = this.getNotFilledMandatoryFields();
    let newHightLightedFieldsValue = {};

    notFilledMandatoryFields.forEach(function(field) {
      newHightLightedFieldsValue[field.Code] = 'mandatory-empty';
    });
    this.setState({ highlightedFields: newHightLightedFieldsValue });
  }

  hasModification() {
    return Object.keys(this.state.modifiedFields).length > 0;
  }

  canSave() {
    return (
      // this.allMandatoryFieldsSet() &&
      contactHasHistory(this.props.contact) !== true || this.hasModification()
    );
  }

  save() {
    this.highlightNotSetMandatoryFields();

    if (this.allMandatoryFieldsSet() !== true) {
      this.props.actions.showNotification({
        message: this.props.labels.klipsoLeads.formNotifMandatoryFieldsToSet,
        level: NotificationLevels.INFO,
      });
      return;
    }

    let updatedContact = Object.assign(this.props.contact, this.state.modifiedFields);

    // Update history
    if (Array.isArray(updatedContact.history) !== true) {
      updatedContact.history = [];
    }
    let currentTime =
      new Date().getTime() +
      (typeof global.klMockHistoryDateDelta === 'number' ? global.klMockHistoryDateDelta : 0);

    // SAN-37 - hardly explainable time shift issue
    currentTime += moment().utcOffset() * 60 * 1000;

    updatedContact.history.unshift({ date: currentTime });
    updatedContact.isSync = false;

    // Persist contact
    saveContact(updatedContact);

    this.exitForm();

    // Background sync - result is ignored / failure is silent
    saveVisitorRelationList();
  }

  exitForm() {
    this.props.actions.klipsoLeadsExitFormScreen();
  }

  getFieldClassName(formField) {
    let highlightStatus = this.state.highlightedFields[formField.Code];
    if (highlightStatus) {
      return 'kl-fs-highlight-' + highlightStatus;
    }

    let value = this.getValue(formField.Code);

    switch (formField.Type) {
      case FIELD_TYPES.EMAIL:
        if (typeof value === 'string' && value.length > 0 && testEmail(value) !== true) {
          return 'generic-form-error';
        }
        break;

      default:
    }

    return '';
  }

  getFormField(code) {
    return this.props.editableFields.find((formField) => formField.Code === code);
  }

  getCodification = (formField, codifId) =>
    formField.Codifications.find((codif) => codif.Id === codifId);

  getValue(code) {
    if (typeof this.state.modifiedFields[code] !== 'undefined') {
      // Field modified but not saved yet
      return this.state.modifiedFields[code];
    }
    return this.props.contact[code];
  }

  getValueLabel(fieldCode, value) {
    let formField = this.getFormField(fieldCode);

    switch (formField.Type) {
      case FIELD_TYPES.SINGLE_CHOICE:
        if (value === null || typeof value === 'undefined') {
          return '';
        } else {
          return this.getCodification(formField, value).Label;
        }

      case FIELD_TYPES.MULTI_CHOICE:
        if (Array.isArray(value)) {
          return value.map((codifId) => this.getCodification(formField, codifId).Label).join(', ');
        }
        break;

      case FIELD_TYPES.DATE_TIME:
        return formatLongDateTime(new Date(), false);

      default:
        return value;
    }
  }

  onValueChange(fieldCode, value) {
    let formField = this.getFormField(fieldCode);
    switch (formField.Type) {
      case FIELD_TYPES.MULTI_CHOICE:
        if (Array.isArray(value)) {
          if (value.length === 0) {
            value = null;
          } else if (value.length > 1) {
            value.sort();
          }
        }
        break;

      case FIELD_TYPES.DATE_TIME:
        if (typeof value === 'string' && value.length > 0) {
          value = new Date(value).getTime();
        }
        break;

      default: // for linter
    }

    // If value has not changed, remove it from modifiedFields
    let contactValue = this.props.contact[fieldCode];
    if ((!contactValue && !value) || JSON.stringify(contactValue) === JSON.stringify(value)) {
      console.log(
        'New value is the same as the original, so removing ' + fieldCode + ' from state.'
      );
      delete this.state.modifiedFields[fieldCode];
      this.setState({
        modifiedFields: Object.assign({}, this.state.modifiedFields),
      });
    } else {
      // Value has been modified
      console.log('New ' + fieldCode + ' value: ', value);
      this.setState({
        modifiedFields: Object.assign({}, this.state.modifiedFields, { [fieldCode]: value }),
      });
    }
  }

  getDialogMaxHeight() {
    return document.documentElement.clientHeight - this.props.keyboardHeight;
  }

  renderEditableFormField(formField) {
    /* {
            "CABPosition": 1,
            "Code": "Prenom",
            "Codifications": [],
            "DefaultValue": "",
            "Id": "Prenom",
            "IsMandatory": false,
            "IsReadOnly": false,
            "Label": "Prénom",
            "Nature": 0, // NB: the field is editable when nature !== 0 and !== 1
            "PrintPosition": 1,
            "Type": 0
        } */

    let inputType = FIELDS_INPUT_TYPE[formField.Type];

    let fieldClassName = [
      'field-without-border-bottom',
      'field-text-align-left',
      this.getFieldClassName(formField),
    ].join(' ');

    switch (formField.Type) {
      case FIELD_TYPES.BOOLEAN:
        return this.renderFormSwitch({
          name: formField.Code,
          placeholder: formField.Label,
          readOnly: formField.IsReadOnly,
          disabled: false,
          required: formField.IsMandatory,
          fieldClassName: fieldClassName,
        });

      default:
        return (
          <InputModal
            key={formField.Code}
            name={formField.Code}
            type={inputType}
            initialValue={this.getValue(formField.Code)}
            readOnly={!inputType || formField.IsReadOnly}
            required={formField.IsMandatory}
            disabled={false}
            label={formField.Label}
            fieldClassName={fieldClassName}
            renderClickableInput={this.renderClickableInput}
            renderModalInput={this.renderModalInput}
            submit={this.onValueChange}
            hideOnSubmit
            labels={this.props.labels}
            okButtonLabel={this.props.labels.common.ok}
            className="kl-fs-modal dialog-min-width"
            maxHeight={this.getDialogMaxHeight()}
          />
        );
    }
  }

  renderClickableInput({
    name,
    className,
    placeholder,
    label,
    // type,
    onBlur,
    readOnly,
    disabled,
    required,
    onClick,
    // onChange,
  }) {
    let value = this.getValue(name);

    let mainClassNames = ['kl-fs-field'];
    if (className) {
      mainClassNames.push(className);
    }

    return (
      <div
        key={name}
        className={mainClassNames.join(' ')}
        readOnly={readOnly}
        disabled={disabled}
        required={required}
        onClick={onClick}
      >
        <div className={'kl-fs-icon ' + (value ? 'kl-fs-icon-check fal fa-check' : '')} />

        <div className="kl-fs-label-and-value">
          <div className="kl-fs-label" dangerouslySetInnerHTML={{ __html: placeholder || label }} />
          {value && <div className="kl-fs-value">{this.getValueLabel(name, value)}</div>}
        </div>

        <div className="kl-fs-chevron fas fa-chevron-right" />
      </div>
    );
  }

  renderFormSwitch({ name, placeholder, readOnly, disabled, required, fieldClassName = '' }) {
    let value = this.getValue(name);

    return (
      <div
        key={name}
        className={`kl-fs-field kl-fs-field-switch ${fieldClassName}`}
        readOnly={readOnly}
        disabled={disabled}
        required={required}
      >
        <div className="kl-fs-icon" />

        <div className="kl-fs-label-and-value">
          <div className="kl-fs-label" dangerouslySetInnerHTML={{ __html: placeholder }} />
        </div>

        <InputSwitch
          name={name}
          value={!!value}
          editable={!readOnly && !disabled}
          submit={this.onValueChange}
        />
      </div>
    );
  }

  parseCheckBoxValue(name) {
    return this.getFormField(name).Codifications.map((codif) => ({
      label: codif.Label,
      value: codif.Id,
    }));
  }

  renderModalInput({
    name,
    className,
    placeholder,
    type,
    value,
    onBlur,
    readOnly,
    disabled,
    required,
    onClick,
    onChange,
  }) {
    switch (type) {
      case 'text': // EDIT_TEXT
      case 'number': // NUMERIC
      case 'date': // DATE_TIME
      case 'email': // EMAIL
      case 'textarea': // TEXT_AREA
        return (
          <TextField
            fieldName={name}
            type={type}
            className={className}
            currentFormValue={value || ''}
            setValue={onChange}
            label={placeholder}
            autofocus
          />
        );

      case 'radio': // SINGLE_CHOICE
      case 'checkbox': // MULTI_CHOICE
        return (
          <CheckBoxes
            fieldName={name}
            className={className}
            currentFormValue={value || []}
            setValue={onChange}
            labels={this.props.labels}
            multiple={type !== 'radio'}
            values={this.parseCheckBoxValue(name)}
          />
        );

      case 'switch': // BOOLEAN - Not displayed using InputModal
        return null;

      default:
        console.error('Unexpected field type: ' + type);
        return null;
    }
  }

  render() {
    console.log(LOG_PREF + 'Render');

    let { context, contact, readOnlyFields, editableFields, labels, actions } = this.props;

    let isExistingContactWithoutModification =
      contactHasHistory(contact) && this.hasModification() !== true;

    let canSave = this.canSave();

    return (
      <div id="kl-form-screen">
        <Header subtitle={context.exhibitor.name} labels={labels} actions={actions} />

        <Content>
          <ContactInfo contact={contact} readOnlyFields={readOnlyFields} labels={labels} />
          <div className="kl-fs-title">{labels.klipsoLeads.formTitle}</div>

          {/* Questionnaire */}
          {editableFields.map(this.renderEditableFormField)}

          {/* History */}
          {contactHasHistory(contact) && (
            <div className="kl-fs-history">
              <div className="kl-fs-history-col1">
                <div className="kl-fs-history-icon far fa-history" />
              </div>
              <div className="kl-fs-history-col2">
                <div className="kl-fs-history-title title-font">
                  {labels.klipsoLeads.historyTitle}
                </div>
                <div className="kl-fs-history-entries">
                  {contact.history.map((hEntry) => (
                    <div key={getDateTimestamp(hEntry.date)}>{dateToString(hEntry.date)}</div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </Content>

        <Footer>
          <FooterGenericButton isTextButton={true} callback={this.exitForm}>
            {isExistingContactWithoutModification
              ? labels.klipsoLeads.close
              : labels.klipsoLeads.cancel}
          </FooterGenericButton>

          {!isExistingContactWithoutModification && (
            <FooterGenericButton
              isTextButton={true}
              callToAction={true}
              disabled={canSave !== true}
              callback={canSave ? this.save : null}
            >
              {labels.klipsoLeads.save}
            </FooterGenericButton>
          )}
        </Footer>
      </div>
    );
  }
}

FormScreen.propTypes = {
  context: PropTypes.object.isRequired, // licence, userName, exhibitor, event, checkpointId...
  contact: PropTypes.object.isRequired, // contact data
  readOnlyFields: PropTypes.array,
  editableFields: PropTypes.array,
  keyboardHeight: PropTypes.number,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default FormScreen;
