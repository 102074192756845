import React from 'react';
import PropTypes from 'prop-types';

import config from 'data/config/config';

import Url from 'src/components/url/Url';

import './TermsOfUse.scss';

function TermsOfUse({ labels }) {
  return (
    <div className="kl-tos">
      <Url
        href={
          config.KLIPSO_LEADS.TOS_LINKS[labels.id]
            ? config.KLIPSO_LEADS.TOS_LINKS[labels.id]
            : config.KLIPSO_LEADS.TOS_LINKS[config.DEFAULT_LANG]
        }
        label={labels.klipsoLeads.tos}
        openInInAppBrowser
      />
    </div>
  );
}

TermsOfUse.propTypes = {
  labels: PropTypes.object,
};

export default TermsOfUse;
