module.exports = {
  name: "ush-2021",
  client: "USH",
  description: "Mobile app for USH 2021",
  pluginId: "mobigeo-plugin-ush",
  validLangs: ["fr"],
  //['en','fr'],
  version: {
    mainVersion: "3.0.2",
    buildVersion: {
      iOS: "20",
      android: "20",
      web: "20"
    }
  },
  native: {
    author: "Mobile-Spot",
    backgroundColor: "0xffffff",
    // iOS
    // appleTeamId: '2BAP3P29V2',
    // iosAppStoreUrl: 'https://itunes.apple.com/app/paris-air-show/id1484463502',
    iosBuildTarget: "11.0",
    // Android
    // 'androidapp-id': 'TODO', // unused?
    // playStoreUrl: 'https://play.google.com/store/apps/details?id=com.mobilespot.sofcot',
    androidFadeSplashScreenDuration: 750,
    androidTargetSdkVersion: 30,
    androidSigning: {
      keyAlias: "mobile-spot",
      keyStorePassword: "jee1Uu0Hieloh7bi"
    },
    // Web app to wrap in the cordova app ('ionic/www', 'simple', 'test', 'demo', 'app-react' - see cordova/testapps/)
    app: "app-react"
  },
  // List the folders of the data bundles to include for this project
  data: {
    default: "zoo5aeXa4g"
  },
  googleMaps: {
    apiKey: ""
  },
  // MobiGeo features
  // 'location', 'location.geogroup', 'location.geopush', 'map.route', 'assets.update', 'poi.aroundme', 'poi.states'
  features: [],
  location: {
    mapping: {},
    registering: {
      location: false,
      geofencing: false
    }
  },
  undeliveredFolders: ["source", "exports"],
  // which additional repos need to be cloned
  relatedRepos: [{
    src: "git@bitbucket.org:mobilespotdev/cordova.git",
    postCommands: ["cd cordova && npm install"]
  }, {
    src: "git@bitbucket.org:mobilespotdev/mobigeo.git",
    postCommands: ["cd mobigeo && npm install"]
  }, {
    src: "git@bitbucket.org:mobilespotdev/app-react.git"
  }, {
    src: "git@bitbucket.org:mobilespotdev/data-ush-2020.git",
    dest: "app-react/data",
    postCommands: ["cd app-react && yarn", "cd app-react && npm run set-env dev", "cd app-react && npm run download-assets", "cd app-react && npm run download-data-files", "cd app-react && npm run update-mobigeo"]
  }],
  crypto: false,
  cordova_plugins: {
    mobigeo_plugin_dependencies: [],
    app_dependencies: [{
      id: "../src-app-plugins/cordova-plugin-mobilespot-app",
      variable: "HOST",
      varValue: "web.url"
    }, {
      id: "../src-app-plugins/cordova-plugin-mobilespot-badge-scan"
    }, {
      id: "cordova-plugin-file"
    }, {
      id: "cordova-plugin-file-opener2",
      variable: "ANDROID_SUPPORT_V4_VERSION",
      rawVarValue: "27.+"
    }]
  },
  web: {}
};