import { getBindedActions } from 'src/store/bindedActions';

if (process.env.NODE_ENV === 'test') {
  global.browserDetect = require('../../../static/browserDetect');
}

export const { isIOS } = global.browserDetect;

export const { isIpad } = global.browserDetect;

export const { getIosVersion } = global.browserDetect;

export const { isWkWebview } = global.browserDetect;

export const { isIphoneX } = global.browserDetect;

export const { isAndroid } = global.browserDetect;

export const { getAndroidVersion } = global.browserDetect;

export const { isIE } = global.browserDetect;

export const { isEdgeBelow16 } = global.browserDetect;

export const { isFirefox } = global.browserDetect;

export const { isChrome } = global.browserDetect;

export const { isCordovaContext } = global.browserDetect;

export const { isMobile } = global.browserDetect;

export const { isSafari } = global.browserDetect;

export function getPlatform() {
  if (isIOS() && isCordovaContext()) return 'ios';
  if (isAndroid() && isCordovaContext()) return 'android';
  else return 'web';
}

export function getBuildVersion() {
  if (isIOS()) return process.env.BUILD_VERSION.iOS;
  if (isAndroid()) return process.env.BUILD_VERSION.android;
  else return process.env.BUILD_VERSION.web;
}

export function getCodePushDeploymentVersion() {
  if (isIOS()) return process.env.CODE_PUSH_DEPLOYMENT_VERSION.iOS;
  if (isAndroid()) return process.env.CODE_PUSH_DEPLOYMENT_VERSION.android;
}

export function getCodePushDeploymentKey() {
  if (isIOS()) return process.env.CODE_PUSH_DEPLOYMENT_KEY.iOS.CodePushDeploymentKey;
  if (isAndroid()) return process.env.CODE_PUSH_DEPLOYMENT_KEY.android.CodePushDeploymentKey;
}

export function addMeaningfulCssClass() {
  const el = document.body.parentNode;

  // Add some helpful css classes indicating on which environment the app is running on
  // Cordova / web
  el.classList.add(global.isCordovaContext ? 'is-cordova' : 'is-web');

  // iOS
  if (isIOS()) {
    el.classList.add('is-ios');
    el.classList.add(`is-ios${getIosVersion()}`);

    if (isIphoneX()) {
      el.classList.add('is-iphonex');
    }
  } else {
    el.classList.add('not-ios');

    // Android
    if (isAndroid()) {
      el.classList.add('is-android');
    } else {
      el.classList.add('not-android');

      // IE
      if (isIE()) {
        el.classList.add('is-ms'); // Used for IE/Edge common fixes
        el.classList.add('is-ie');
      }
      // Edge
      else if (isEdgeBelow16()) {
        el.classList.add('is-ms'); // Used for IE/Edge common fixes
        el.classList.add('is-old-edge');
      }
    }
  }
}

/**
 * One more iOS hack
 * see: https://github.com/apache/cordova-ios/issues/417
 * and: https://openradar.appspot.com/radar?id=5018321736957952
 *
 * After keyboard has been displayed, events Y position is shifted
 */
export function iosHackScrollTo0() {
  if (global.isCordovaContext && isIOS()) {
    window.scrollTo(0, 0);
  }
}

/**
 * see https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
 */
export function initiVisiblityChangeListener() {
  // Set the name of the hidden property and the change event for visibility
  let hidden;
  let visibilityChange;

  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  if (!hidden || !visibilityChange) {
    console.error('[browser] Cannot determine Page Visibility API property and event name to use');
    return;
  }

  document.addEventListener(
    visibilityChange,
    function onDocumentVisiblityChange() {
      getBindedActions().documentVisible(!document[hidden]);
    },
    false
  );
}
