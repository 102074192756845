import React, { Fragment } from 'react';

import { getValueFromStringAttribute } from 'src/core/util/JsTools';
import renderField from './renderField';

const LOG_PREF = '[SearchForm renderToggleButton] ';

export default function({
  toggleButtonConfig,
  fieldsHooks,
  performSearch,
  isSearchOngoing,
  labels,
  setFieldVisiblity,
  toggleButtonsVisiblityHooks,
}) {
  // Skip if config is empty
  if (
    !toggleButtonConfig ||
    Array.isArray(toggleButtonConfig.content) !== true ||
    toggleButtonConfig.content.length === 0
  ) {
    return null;
  }

  const [visibleIndex, setVisibleIndex] = toggleButtonsVisiblityHooks[toggleButtonConfig.key];

  return (
    <Fragment key={toggleButtonConfig.key}>
      <div id="sfs-toggle-btns">
        {toggleButtonConfig.content.map((buttonConfig, index) => (
          <div
            key={index}
            className={index === visibleIndex ? 'sfs-tb-selected' : ''}
            onClick={() => {
              setVisibleIndex(index);
            }}
          >
            <span>{getValueFromStringAttribute(labels, buttonConfig.label)}</span>
          </div>
        ))}
      </div>

      <div className="generic-field-row">
        {toggleButtonConfig.content.map(function(buttonConfig, index) {
          if (index !== visibleIndex) {
            // setFieldVisiblity(fieldName, false);
            return null;
          }

          // Check config
          if (!buttonConfig.field) {
            console.error(`${LOG_PREF}Missing 'field' prop for button: `, buttonConfig);
            return null;
          }
          const fieldNames = Object.keys(buttonConfig.field);
          if (fieldNames.length === 0 || fieldNames.length > 1) {
            console.error(`${LOG_PREF}Invalid 'field' configuration for button: `, buttonConfig);
            return null;
          }

          const fieldName = fieldNames[0];
          const fieldConf = buttonConfig.field[fieldName];

          return renderField({
            fieldName,
            fieldConf,
            fieldsHooks,
            performSearch,
            isSearchOngoing,
            labels,
            setFieldVisiblity,
          });
        })}
      </div>
    </Fragment>
  );
}

/*

    function setByCompany() {
        setSimpleSearchFieldType(BY_COMPANY_TYPE);
    }
    function setByName() {
        setSimpleSearchFieldType(BY_NAME_TYPE);
    }

    function _setCompany(e) {
        setCompany(e.target.value);
    }
    function _setName(e) {
        setName(e.target.value);
    }

    return (
        <>
            <div id="sfs-toggle-btns">
                <div className={ simpleSearchFieldType === BY_COMPANY_TYPE ? 'sfs-tb-selected' : '' }
                     onClick={setByCompany}
                     >
                    <span>{labels.searchTaiga.fieldToggle.byCompany}</span>
                </div>

                <div className={ simpleSearchFieldType === BY_NAME_TYPE ? 'sfs-tb-selected' : '' }
                     onClick={setByName}
                     >
                    <span>{labels.searchTaiga.fieldToggle.byName}</span>
                </div>
            </div>

            <div className="generic-field-row">
                { simpleSearchFieldType === BY_COMPANY_TYPE &&
                    <input
                        value={company}
                        onChange={_setCompany}
                        placeholder={labels.searchTaiga.fields.company}
                    />
                }

                { simpleSearchFieldType === BY_NAME_TYPE &&
                    <input
                        value={name}
                        onChange={_setName}
                        placeholder={labels.searchTaiga.fields.name}
                    />
                }

                <CTAButton
                    id="sfs-search-btn-small"
                    label=""
                    action={performSearch}
                    isEnabled={!isSearchOngoing}
                    >
                    <div className="fas fa-search"></div>
                </CTAButton>
            </div>


 */
