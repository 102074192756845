var defaultConf = require('./config-default');

var BO_URL = 'ush-data-dev.mobile-spot.com'; // Data backend domain name

var BO_TAIGA_URL = '';
var NODE_BACKEND_DOMAIN = 'ush-node-backend-dev.mobile-spot.com'; // Node backend domain name (optional)

var NODE_WS_BAKEND_DOMAIN = 'ush-node-backend-ws-dev.mobile-spot.com'; // Node backend web sockets (optional)

module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: BO_URL,
  // no trailing '/'
  BO_PATH: '/home/www/ush/ush2020/ush-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '/home/www/ush/ush2020/ush-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-18',
  // GA ID
  LOGIN: {
    forgottenPasswordURL: "".concat(BO_TAIGA_URL, "/mobile/authentification/mot-de-passe-oublie.htm")
  },
  projectId: '15160331717',
  // Google FCM Sender ID
  appId: 'DD19C-27ACE',
  // Pushwoosh app Id
  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt"),
  // add per environnement
  SYNCHRONIZED_FAVORITES: {
    FEATURE_ENABLED: false,
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/synchro-favorites")
  },

  /* REAL_TIME: {
    URL: `https://${NODE_WS_BAKEND_DOMAIN}`,
  }, */
  AGENDA_AS_PDF: {
    URL: "http://ush-data-dev.mobile-spot.com/pdfs/Programme_Congres_Hlm_2019.pdf" // pdf name must match node_backend config

  },
  SOCIAL: {
    FEATURE_ENABLED: false,
    TWITTER: {
      POST_PER_PAGE: 10
    },

    /* YOUTUBE: {
      POST_PER_PAGE: 5,
    }, */
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/social")
  }
});