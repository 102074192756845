import React from 'react';
import PropTypes from 'prop-types';

import './Footer.scss';

function Footer({ children, onClick, justifyContent }) {
  return (
    <div
      className="klipso-leads-footer content-font"
      onClick={onClick}
      style={typeof justifyContent === 'string' ? { justifyContent } : null}
    >
      {children}
    </div>
  );
}

Footer.propTypes = {
  onClick: PropTypes.func,
  justifyContent: PropTypes.string, // ability to override default justify-content
};

export default Footer;
